import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

import { reactive, toRefs } from '@vue/composition-api'
const state = reactive({
	showModal: true,
	isWaitingModal: false,
	showClose: true,
})
export default function storeOurCompanyState() {
	return { ...toRefs(state) }
}
