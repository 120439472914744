<template>
	<LayoutDefaultNew>
		<div>
			<div v-if="!showModal">
				<div>
					<div>
						<span
							><b>{{ $t('message.pages.footer.user') }}</b
							>{{ userName }}</span
						>
					</div>
				</div>
				<div>
					<span>
						<b>{{ $t('message.pages.footer.company') }}</b
						>{{ userCurrentCompany.toUpperCase() }}
					</span>
				</div>
				<div>
					<img
						style="display: block; width: 150px; height: auto"
						id="base64logo"
						v-bind:src="currentLogoLink"
					/>
				</div>
			</div>
			<div v-if="showModal">
				<modal-our-company-frame />
			</div>
			<div v-if="!showModal && showSwitchButton">
				<b-button
					:disabled="isWaitingModal"
					class="is-success switch-button"
					v-if="showSwitchButton"
					@click="setShowModalTrue"
					style="width: 250px; margin-top: 20px"
				>
					Switch the company
				</b-button>
			</div>
		</div>
	</LayoutDefaultNew>
</template>

<script>
import Store from '@/store'
import { computed, onMounted, ref } from '@vue/composition-api'
import ModalOurCompanyFrame from '@/_srcv2/pages/home/sub-component/modal/ModalOurCompanyFrame.vue'
import storeOurCompanyState from '@/_srcv2/pages/home/sub-component/useGetOurCompanyDetails.js'

export default {
	name: 'Home',
	components: {
		ModalOurCompanyFrame,
	},
	setup() {
		const isBtnDisabled = ref(false)
		const currentLogoLink = computed(() => Store.getters.getLogoLink)
		const userName = computed(() => Store.getters.getUserName.toUpperCase())
		const userCurrentCompany = computed(() =>
			Store.getters.getUserCurrentCompany.toUpperCase(),
		)
		const showSwitchButton = computed(
			() => Store.getters.getUserGroups.length !== 1,
		)
		const { showModal, isWaitingModal } = storeOurCompanyState()
		onMounted(() => {
			isBtnDisabled.value = false
			showModal.value = true
		})
		const setShowModalTrue = () => {
			isWaitingModal.value = true
			setTimeout(() => {
				showModal.value = true
			}, 0)
		}
		return {
			currentLogoLink,
			userName,
			userCurrentCompany,
			showModal,
			isWaitingModal,
			showSwitchButton,
			setShowModalTrue,
		}
	},
}
</script>

<style scoped>
.switch-button {
	margin-top: 20px;
}
</style>
