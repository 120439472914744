import { render, staticRenderFns } from "./ModalOurCompanyFrame.vue?vue&type=template&id=cc9e0d3a&scoped=true&"
import script from "./ModalOurCompanyFrame.vue?vue&type=script&lang=js&"
export * from "./ModalOurCompanyFrame.vue?vue&type=script&lang=js&"
import style0 from "./ModalOurCompanyFrame.vue?vue&type=style&index=0&id=cc9e0d3a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc9e0d3a",
  null
  
)

export default component.exports