<!-- @format -->

<template>
	<div>
		<div v-if="!!showClose">
			<b>Switch the Company</b>
		</div>
		<div v-if="!showClose">
			<b>Select the Company</b>
		</div>
		<div v-for="userGroup in userGroups" :key="userGroup">
			<b-button
				:disabled="isBtnDisabled"
				id="companyBtn"
				class="is-primary is-select-button"
				@click="setCurrentCompany(userGroup)"
			>
				{{ userGroup.toUpperCase() }}
			</b-button>
		</div>
	</div>
</template>

<script>
import GetOurCompaniesDetailsQuery from '@/_srcv2/pages/home/graphql/GetOurCompaniesDetailsQuery.graphql'
import storeOurCompanyState from '@/_srcv2/pages/home/sub-component/useGetOurCompanyDetails.js'
import { useQuery } from '@vue/apollo-composable'
import { reactive, onMounted, ref } from '@vue/composition-api'
import Store from '@/store'

export default {
	name: 'GetOurCompanyDetails',
	setup() {
		const userGroups = ref([])
		userGroups.value = Store.getters.getUserGroups
		const { showModal, showClose } = storeOurCompanyState()
		// todo ------------------------------------------------------------------------
		const checkGuard = (role) => {
			return Store.getters.getUserRoles.includes(role)
		}
		const setGuard = (roles) => {
			return new Promise((resolve, reject) => {
				if (roles !== null || undefined) {
					Store.dispatch(
						'setAccountantGuard',
						checkGuard('dev') || checkGuard('accountant'),
					)
					Store.dispatch(
						'setStockGuard',
						checkGuard('dev') || checkGuard('suadmin') || checkGuard('stock'),
					)
					Store.dispatch(
						'setCompaniesGuard',
						checkGuard('dev') ||
							checkGuard('suadmin') ||
							checkGuard('companies'),
					)
					Store.dispatch(
						'setOrderGuard',
						checkGuard('dev') || checkGuard('order') || checkGuard('suadmin'),
					)
					Store.dispatch(
						'setDispatchGuard',
						checkGuard('dev') ||
							checkGuard('dispatch') ||
							checkGuard('suadmin'),
					)
					Store.dispatch(
						'setInvoiceGuard',
						checkGuard('dev') || checkGuard('suadmin') || checkGuard('invoice'),
					)
					Store.dispatch(
						'setSaleInvoiceGuard',
						checkGuard('dev') ||
							checkGuard('suadmin') ||
							checkGuard('salesInvoice'),
					)
					Store.dispatch(
						'setPurchaseInvoiceGuard',
						checkGuard('dev') ||
							checkGuard('suadmin') ||
							checkGuard('purchaseInvoice'),
					)
					Store.dispatch(
						'setReminderGuard',
						checkGuard('dev') ||
							checkGuard('suadmin') ||
							checkGuard('reminder'),
					)
					Store.dispatch(
						'setTransactionsGuard',
						checkGuard('dev') ||
							checkGuard('suadmin') ||
							checkGuard('transactions'),
					)
					Store.dispatch(
						'setAccountingGuard',
						checkGuard('dev') ||
							checkGuard('suadmin') ||
							checkGuard('accounting'),
					)
					Store.dispatch(
						'setMarketingGuard',
						checkGuard('dev') ||
							checkGuard('suadmin') ||
							checkGuard('marketing'),
					)
					Store.dispatch(
						'setUtilitiesGuard',
						checkGuard('dev') ||
							checkGuard('suadmin') ||
							checkGuard('marketing') ||
							checkGuard('utilities'),
					)
					Store.dispatch(
						'setAdminGuard',
						checkGuard('dev') || checkGuard('suadmin'),
					)
					Store.dispatch('setDevGuard', checkGuard('dev'))
					Store.dispatch(
						'setOldOrderGuard',
						checkGuard('dev') ||
							checkGuard('suadmin') ||
							checkGuard('oldOrder'),
					)
					Store.dispatch(
						'setOldDispatchGuard',
						checkGuard('dev') ||
							checkGuard('suadmin') ||
							checkGuard('oldDispatch'),
					)
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		onMounted(() => {
			isBtnDisabled.value = false
			setGuard()
				.then(() => {
					console.log('Guard are set!!!')
					Store.dispatch('setIsGuardSet', true)
				})
				.then(getUserCurrentCompany)
		})
		// ****************************************************************************************
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetch, loading } = useQuery(
			GetOurCompaniesDetailsQuery,
			() => ({
				company_nickname: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		// -------------------------------------------------------------------
		const setCurrentOurCompany = (ourCompany) => {
			Store.dispatch('setLogoLink', ourCompany.company_image_link)
			Store.dispatch('setLogo', ourCompany.company_logo_64base)
			Store.dispatch('setAboutUs', ourCompany.about_us_64base)
			Store.dispatch('setCurrentCompanyTitle', ourCompany.company_name)
			Store.dispatch('setWatermark', ourCompany.company_watermark_64base)
			Store.dispatch('setOurCompanies', ourCompany)
		}
		// ------------------------------------------------------------------
		const isBtnDisabled = ref(false)
		const setCurrentCompany = (payload) => {
			isBtnDisabled.value = true
			setCompany(payload)
		}
		const setCompany = (payload) => {
			return new Promise((resolve, reject) => {
				if (payload !== null || undefined) {
					Store.dispatch('setUserCurrentCompany', payload)
						.then(refetch)
						.then((result) => {
							console.log(result)
							setCurrentOurCompany(result.data.companies[0])
						})
						.then(() => (showModal.value = false))
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const getUserCurrentCompany = () => {
			return new Promise((resolve, reject) => {
				console.log(
					'Store.getters.getUserGroups[0]',
					Store.getters.getUserGroups[0],
				)
				console.log(
					'Store.getters.getUserGroups.length',
					Store.getters.getUserGroups.length,
				)
				if (Store.getters.getUserGroups.length !== 0) {
					if (Store.getters.getUserGroups.length === 1) {
						setCompany(Store.getters.getUserGroups[0])
					} else {
						showModal.value = true
					}
					resolve('User Current Company is set')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// -------------------------------------------------------------------
		return {
			isBtnDisabled,
			loading,
			userGroups,
			setCurrentCompany,
			showClose,
		}
	},
}
</script>

<style scoped>
.is-select-button {
	margin: 10px 0 5px 0;
	width: 250px;
}
</style>
